/*
 * @Author: mulingyuer
 * @Date: 2021-04-08 16:04:13
 * @LastEditTime: 2021-04-27 16:20:38
 * @LastEditors: mulingyuer
 * @Description:
 * @FilePath: \saas-admin-vue\src\modules\vote\api\vote-list.js
 * 怎么可能会有bug！！！
 */
import api from '@/base/utils/request';
import download from "@/base/utils/download";

// 插件列表
export const pluginsInfo = data => {
  return api({
    url: '/admin/cyc/overview/plugins',
    method: 'post',
    data
  });
};

// 客服配置
export const rightNavConfig = data => {
  return api({
    url: '/admin/admin/nav/rightNavConfig',
    method: 'post',
    data
  });
};


