<!--
 * @Author: mulingyuer
 * @Date: 2021-04-08 15:42:35
 * @LastEditTime: 2021-05-17 17:03:03
 * @LastEditors: mulingyuer
 * @Description: 投票列表
 * @FilePath: \saas-admin-vue\src\modules\vote\views\vote-list.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="plugin-overview">
    <div class="plugin-box">
      <div
        class="content-module"
        v-for="(plugin, pluginIndex) in pluginsInfo"
        :key="pluginIndex"
      >
        <div class="module-title">{{ plugin.name }}</div>
        <div class="plugin-list">
          <div
            class="plugin-item"
            :class="[!item.status ? 'hideGray' : '']"
            v-for="(item, i) in plugin.plugins"
            :key="i"
            @click="clickPlugin(item)"
          >
            <img class="icon" :src="item.icon" />
            <div class="name">{{ item.name }}</div>
          </div>
        </div>
      </div>
    </div>
    <ContactDialog v-model="showDialog" :config="navConfig"></ContactDialog>
  </div>
</template>
<script>
import { pluginsInfo, rightNavConfig } from '../api/plugins-overview'
import ContactDialog from '@/modules/plugins-overview/components/ContactDialog.vue'
export default {
  data() {
    return {
      loading: false, //加载中
      // 插件列表
      pluginsInfo: [],
      showDialog: false,
      // nav配置
      navConfig: {
        wechat_mobile_image: '',
        wechat_web_image: '',
      },
    }
  },
  methods: {
    getRightNavConfig() {
      rightNavConfig().then((res) => {
        this.navConfig = res.data.config
      })
    },
    // 获取插件列表
    getOverview() {
      this.loading = true
      pluginsInfo()
        .then((res) => {
          console.log(res)
          this.pluginsInfo = res.data.list
          this.loading = false
        })
        .catch((err) => {
          this.loading = false
        })
    },
    // 点击插件
    clickPlugin(item) {
      console.log(item)
      if (!item.status) {
        // 联系客服
        this.showDialog = true
      } else if (!item.auth) {
        // 没权限
        this.$message.info('暂无权限，请联系管理员授权。')
      } else if (item.status) {
        this.$router.push({ name: item.url }).catch(() => {})
      }
    },
  },
  created() {
    this.getOverview()
    this.getRightNavConfig()
  },
  components: {
    ContactDialog,
  },
}
</script>
<style lang="scss">
.plugin-overview {
  .plugin-box {
    .content-module {
      .module-title {
        color: #000000;
        font-family: 'PingFang SC';
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
      }

      .plugin-list {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        gap: 8px;
        margin-top: 12px;
        .plugin-item {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          background-color: #fff;
          border-radius: 8px;
          padding: 24px 0;
          cursor: pointer;
          .icon {
            width: 68px;
            height: 68px;
          }

          .name {
            margin-top: 16px;
            color: #000000;
            font-family: 'PingFang SC';
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }
        // 图片变灰
        .hideGray {
          -webkit-filter: grayscale(1); /* Webkit */
          filter: gray; /* IE6-9 */
          filter: grayscale(1); /* W3C */
        }
      }
    }
    .content-module + .content-module {
      margin-top: 20px;
    }
  }
}
</style>
