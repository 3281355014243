<template>
  <el-dialog
    class="contact-dialog dialog-vertical"
    width="400px"
    title=""
    :visible="open"
    @close="close"
  >
    <template #title>
      <div class="title">
        当前版本不支持该功能，如需使用此功能请进行版本升级，扫码添加客服人员
      </div>
    </template>
    <div class="content" v-loading="loading">
      <img class="qrcode" :src="config.wechat_web_image" />
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'ContactDialog',
  components: {},
  model: {
    prop: 'open',
    event: 'changeOpen',
  },
  props: {
    // 弹窗配置
    open: {
      type: Boolean,
      detault: false,
    },
    // 弹窗配置
    config: {
      type: Object,
      detault: () => {},
    },
  },
  data() {
    return {
      loading: false,
      rules: {},
    }
  },
  methods: {
    close() {
      this.$emit('changeOpen', false)
    },
  },
}
</script>

<style lang="scss" scoped>
.title {
  font-size: 16px;
  padding-right: 30px;
  position: relative;
}
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .qrcode {
    width: 300px;
    height: 300px;
    // background-color: pink;
  }
}
</style>
